.contact-us-view {

    .contact-details-container {
        height: 100%;
        padding: 2rem;
    }
    table.contact-details {
        width: 100%;
        height: 100%;

        td {
            width: 50%;
        }

        tr:first-child {
            td {
                border-bottom: 1px solid $form-border;
            }
        }
        tr td:first-child {
            border-right: 1px solid $form-border
        }
    }

    .contact-method {
        text-align: center;
        padding: 2rem 1rem;

        .contact-method-name {
            font-size: 16px;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }
        .contact-method-value {
            color: $bs-info;
            font-size: 18px;
        }
    }
}
@include media-breakpoint-down(lg) {
    .contact-us-view {
        .contact-details-container {
            padding: 2rem 0;
        }
    }
}
@include media-breakpoint-down(md) {
    .contact-us-view {
        .contact-details-container {
            height: auto;
            padding: 1rem;
            margin-bottom: 2rem;
        }

        .contact-method {
            padding: 3rem 1rem;
        }
    }
}