.grid-container {
    background: #fff;
    padding: 10px 0;
    border-radius: 10px;
    & .grid-footer-text {
        padding: 7px 24px 0px 24px;
        color: grey;
        border-top: 1px solid rgba(211,211,211, 0.8);
    }
}

table.table {
    width: 100%;
    margin-bottom: 0px;
    border: none;
    color: inherit;

    .form-control {
        border-radius: 1px; 
    }
    > thead th {
        border: none;
        padding: 20px 7px;
        vertical-align: middle;
        background: #fff;
        color: $text-lighter;
        font-weight: normal;
        font-size: 13px;
        text-transform: uppercase;
    }
    > tfoot th {
        font-weight: 500;
        padding: 7px;
    }
    > tfoot th.editable-column {
        font-weight: 500;
        padding: 7px 15px;
    }
    .table-bordered thead td, .table-bordered thead th {
         border-bottom-width: 1px; 
    }

    tr:nth-child(odd) {
        background: #F2F2F2;
    }
    td {
        padding: 7px;
        vertical-align: middle;
        border: none;

        &.color-alt {
            color: #808080;
        }
        &.color-highlight {
            color: #000000
        }
    } 

    th, td {
        &:first-child {
            padding-left: 1.5rem;
        }
        &:last-child {
            padding-right: 1.5rem;
        }
    }

    .btn {
        font-size: 13px;
    }

    @media (max-width: 768px) {
        th, td {
            &:first-child {
                padding-left: 0.5rem;
            }
            &:last-child {
                padding-right: 0.5rem;
            }
        }
    }
}