$lg-width: 300px;
$xl-width: 350px;
$xxl-width: 400px;

.big-sidebar {
    padding-top: 75px;
    padding-bottom: 25px;
    background-color: $etfSA-black-primary;
    color:white;

    .big-sidebar-content {
        padding-left: calc(50% - 75px);
    }
    .motto {
        font-size: 22px;
        line-height: 1.4;
        padding-top: 1.5rem;
    }
}

@include media-breakpoint-up(lg) {
    .big-sidebar {
        position: fixed;
        width: $lg-width;
        left: 0;
        top: 0;
        bottom: 0;
    }
    .big-sidebar-container {
        .app-right-panel, .app-footer {
            margin-left: $lg-width;
        }
    }
}
@include media-breakpoint-up(xl) {
    .big-sidebar {
        width: $xl-width;
    }
    .big-sidebar-container {
        .app-right-panel, .app-footer {
            margin-left: $xl-width;
        }
    }
}
@include media-breakpoint-up(xxl) {
    .big-sidebar {
        width: $xxl-width;
    }
    .big-sidebar-container {
        .container {
            max-width: 1200px;
        }
        .app-right-panel, .app-footer {
            margin-left: $xxl-width;
        }
    }
}

