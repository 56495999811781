.app-user-container {
    margin-top: 8px;
    z-index: 2;
    display: inline-flex;

    .app-menu-items {
        display: flex;
        margin-right: 1rem;

        .app-icon {
            display: flex;
            cursor: pointer;
            margin-right: 0.5rem;
    
            >p {
                margin-top: 18px;
                margin-left: 5px;
            }
        }
    }

    .app-user-header {
        display: inline-flex;
        align-items: center;
        text-align: left;
        cursor: pointer;

        .app-user-icon {
            font-size: 20px;
            padding: 2px 9px;
            display: inline-block;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 50%;
        }
        .app-user-name {
            margin-left: 0.5rem;
            display: inline-block;
            line-height: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(sm) {

    .app-user-container {
        .app-user-header {
            .app-user-name {
                display: none;
            }
        }
        .app-menu-items {
            margin-right: 0.5rem;

            .app-icon {
                >p {
                    display: none;
                }
            }
        }
    }
}