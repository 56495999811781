.faq-page {
    
    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .form-group input {
        background-color: #fafcfe;
        border: 1px solid #eaedf7;
        border-radius: 10px;
        padding: 0.75rem 2rem;
        height: auto;

        &::placeholder {
            color: #97a0c3;
        }
    }

    .faq-container {

        .faq-header {
            background-color: #fafcfe;
            border: 1px solid #eaedf7;
            border-radius: 10px;
            padding: 1rem 2rem;
    
            h5 {
                margin: 0;
                font-weight: normal;
            }
        }
    
        .faq-answer {
            padding: 1rem 2rem;
            color: #636e95;
        }
    }
}