body {
    font-family: 'Ubuntu';
    font-size: 15px;
    color: $text-color;
}

a {
    color: $etfSA-black-primary;
    font-size: 14px;
    transition: all .3s ease-in-out;
    position: relative;

    &:hover {
        color: $etfSA-black-primary;
        text-decoration: none;
        animation: animateLink 1s normal;
    }
       
    &[disabled] {
        pointer-events: none;
        color: $text-lighter;
        text-decoration: none;
    }
}

@keyframes animateLink {
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}

div.container-xl {
    max-width: 1160px;
    padding: 0px 15px;
    margin: 0px -15px;
}

.page-progress-bar {
    position: fixed;
    top: 0px;
    border-radius: 0;
    height: 8px;
    width: 100%;
}

.app-container.has-side-menu {
    background: #f5f5f5;
}

.app-header-panel {
    background-color: $etfSA-black-primary;
    color: #fff;
    height: 80px;
    z-index: 1;
    margin-bottom: 2rem;
    display: flex;

    >.container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .app-header-logo {
        position: absolute;
        text-align: left;
        top: 5px;
        z-index: 2;
        width: 170px;
        height: 70px;
        padding-top: 18px;
        padding-bottom: 18px;
        background: $etfSA-black-primary;
    }

    .app-header-end {
        flex-grow: 1;
        text-align: right;
    }

    .app-hamburger {
        display: none;
        cursor: pointer;
    }

    .app-breadcrumb {
        float: left;
        padding: 12px 0px;
    }

    .container {
        position: relative;
    }
}

div.bread-crumb {
    display: inline;
    color: $text-light;

    div.bread-crumb-level {
        display: inline;

        span.bread-crumb-separator {
            padding: 0 10px;
        }
    }
}

.app-right-panel {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.app-footer {
    padding-bottom: 0.5rem;
    padding-top: 1.5rem;

    .app-footer-divider {
        border-bottom: 1px solid #d2d5e1;
        text-align: center;

        img {
            position: relative;
            top: 1px;
        }
    }

    .app-footer-content {
        padding-top: 12px;
        img.footer-logo {
            max-width: 65px;
        }

        .col-footer-content {
            align-content: center;
        }
    }
}

div.toast-container {
    position: fixed;
    right: 15px;
    top: 100px;
    backdrop-filter: blur(3px);
    border-radius: 5px;
}

.neo-card.card {
    border: none;
}

@media (max-width: 1360.98px) {
    .investor-hub-logo {
        width: 105px;
    }
}

@include media-breakpoint-up(xxl) {
    .app-right-panel {
        padding-top: 1rem;
    }
}

@include media-breakpoint-down(lg) {
    .app-footer {
        font-size: 13px;
    }
}

@include media-breakpoint-down(sm) {
    .app-header-panel {
        .app-hamburger {
            display: block;
        }
    }

    .app-footer {
        font-size: 12px;
    }

    .app-header-logo {
        left: 45px;
    }

    .col-footer-content {
        margin-top: 5px;
        text-align: center;
        max-width: fit-content;
    }
}

@include media-breakpoint-down(xs) {
    .app-header-logo {
        left: 45px;
    }
}

.neo-file-drop {
    border-radius: 8px;
}

.neo-file-drop-uploaded {
    border: 2px solid #00c056;
}

.upload-icon {
    background-image: url("../assets/img/icon-upload.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 43px;
    background-color: transparent;
    border: none;
    margin-top: 10px;
    transition: none;
}

.upload-icon:hover {
    background-image: url("../assets/img/icon-upload.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
}

.upload-icon:focus {
    background-image: url("../assets/img/icon-upload.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border-color: transparent;
    border: none;
}

.upload-completed-icon {
    background-image: url("../assets/img/complete.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 43px;
    background-color: transparent;
    border: none;
    margin-top: 10px;
    transition: none;
}

.upload-completed-icon:hover {
    background-image: url("../assets/img/complete.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
}

.upload-completed-icon:focus {
    background-image: url("../assets/img/complete.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border-color: transparent;
    border: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background: #dce9f7;
    border: solid 1px #dce9f7;
}

.upload-text {
    color: #636e95;
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
}

.file-type-text {
    color: #636e95;
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
}

.file-download-text {
    text-align: center;
    font-size: 14px;
    margin-bottom: 0px;
}

.required-indicator {
    color: red;
}

.maintenance-doc-links {
    min-width: auto;
    color: #636e95;
    text-transform: none;
}

.link-smaller {
    font-size: smaller;
    font-weight: bold;
}

.link-medium {
    font-size: medium;
    font-weight: bold;
}

.icon-btn {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    i {
        font-size: 20px;
    }
}

.icon-btn-block {
    display: block;
}

.download-report-btn {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    margin-right: 1px;
    margin-bottom: 10px;
}

.soft-disable {
    cursor: pointer;
    color: $text-lighter;
    text-decoration: none;
    font-size: 14px;
}