@import "../Styles/Variables.scss";

.sidebar {
    position: fixed;
    top: 128px;

    .sidebar-overlay {
        display: none;
    }

    .sidebar-menu {
        color: $bs-primary;

        .sidebar-menu-item {
            padding: 1rem 0.75rem;
            border-radius: 0px 10px 10px 0px;
            margin-top: 2px;
            border-left: 3px solid transparent;
            color: #7c88b1;

            .sidebar-menu-icon {
                width: 32px;
                font-size: 21px;
                text-align: center;
            }

            .sidebar-menu-name {
                display: none;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.75);
            }
            
            &.active {
                background: #fff;
                border-color: $bs-primary;
                color: $bs-primary;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .sidebar {
        display: none;

        &.show {
            display: block;
            width: 100%;
            z-index: 1;
        }

        .sidebar-overlay {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,0.3);
        }

        .sidebar-menu {
            position: relative;
            background: #fff;
            z-index: 1;

            .sidebar-menu-item {
                display: flex;
                align-items: baseline;

                .sidebar-menu-name {
                    margin-left: 1rem;
                    display: inline;
                }
            }
        }
    }
}