.app-info-panel {
    position: absolute;
    top: 100%;
    right: 1rem;
    width: 500px;
    background: #fff;
    box-shadow: -4px 0 12px 4px rgba(0, 0, 0, 0.04);
    color: $text-color;
    z-index: 1;
    overflow-x: hidden;
    opacity: 0;
    transition: all ease 0.3s;

    &.show {
        opacity: 1;
    }

    .info-panel-bump {
        position: absolute;
        width: 24px;
        top: 0;
    }

    a.link-with-arrow {
        display: inline-flex;
        justify-content: space-between;
        align-items: baseline;
        min-width: 220px;
        padding: 2px 0;
    }

    a.back {
        font-size: 12px;
    }

    .info-panel-column {
        padding: 2rem;
        width: 100%;
        position: absolute;
        top: 0;
        transition: all ease 0.3s;
        transform: none;
    }

    .offset-left {
        transform: translateX(-500px);
    }

    .offset-right {
        transform: translateX(500px);
    }

    .custom-control label {
        font-weight: normal;
    }

    .panel-grid-container {
        margin: 0 -2rem;

        table.table {
            th, td {
                &:first-child {
                    padding-left: 2rem;
                }
                &:last-child {
                    padding-right: 2rem;
                }
            }
        }
    }

    .brokerage-section {
        .form-group {
            margin-bottom: 0;

            .form-control {
                border: none;
                color: inherit;
                padding-top: 0;
                padding-bottom: 0;
                height: auto;
            }
            label {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}

.account-information-panel {
    .info-panel-status-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            margin: 0;
        }

        .status-image {
            margin: -5px 0;
        }
    }
}

.downloads-info-panel {
    a.link-with-arrow {
        min-width: 270px;
    }
}

@include media-breakpoint-down(lg) {
    .app-info-panel {
        .info-panel-column {
            padding-top: 3rem;
        }
    }
}
@include media-breakpoint-down(xs) {
    .app-info-panel {
        right: 0;
        left: 0;
        width: 100%;
    }
}