.product-container {

  .header {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0;
  }

  @media only screen and (max-width: 821px) {
    .header {
      display: block;
    }   

    .product-button {
      width: 45%;
    }

    .product-button-container {
      display: flex;
      margin-top: 15px;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    padding: 10px 0;
  }

  .components-container {
    background-color: white;
    border-radius: 7px;
    padding: 15px 0;

    .filters {
      >:first-child {
        display: flex;
        justify-content: center;
        padding: 10px 20px 20px 50px;
        border-bottom: solid 1px #d4d6e2;
        align-items: center;
      }

      >:nth-child(2) {
        padding: 15px 90px;
        border-bottom: solid 1px #d4d6e2;
        height: 120px;
        margin-left: 0;
        margin-right: 0;
      }

      @media only screen and (max-width: 1023px) {
        >:first-child {
          padding: 10px 15px 20px 0px;
          width: 100%;
          font-size: 20px;
        }

        >:nth-child(2) {
          display: inline-block;
          padding: 20px 0px;
          border-bottom: none;
          width: 100%;
        }
      }

      @media only screen and (min-width: 821px) and (max-width: 1024px) {
         >:nth-child(2) {
          padding: 15px 0px;
        }

        .product-list {
          padding-left: 0px;
        }
      }
    }

    .product-list {
      min-height: 38vh;
      padding-left: 80px;
    }

    @media only screen and (max-width: 821px) {
      .product-list {
        min-height: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 50px;
        border-top: solid 1px #d4d6e2;
      }   
    }

    .no-results {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;

      img {
        height: 5vh;
        margin: 10px;
      }

      p {
        font-weight: bold;
        font-size: larger;
      }
    }
  }

  /* controls */
  select {
    min-height: calc(1.5em + 1.75rem + 2px);
    border-radius: 7px !important;
    font-size: 1rem;
  }

  @media only screen and (min-width: 576px) and (max-width: 1023px) {
    select {
      width: 285px;
    }

    .react-select-bs__control {
      width: 285px;
    }
  }

  .react-select-bs__control {
    min-height: calc(1.5em + 1.75rem + 3px) !important;
    border-radius: 7px !important;
    padding-left: 2px;
  }

  .react-select-bs__placeholder {
    color: #495057;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 2px;
  }

  .react-select-bs__multi-value__label {
    background-color: #ba2840;
    color: white !important;
  }

  .css-g1d714-ValueContainer {
    flex-wrap: nowrap;
}

  .clear-filters {
    width: 50px;
    background: #f5f5f5;
    padding: 14px;
    border-radius: 7px;
  }

  /* end controls */

  .product {
    position: relative;
    display: inline-flex;
    text-align: left;
    width: 288px;
    min-height: 98px;
    height: auto;
    padding: 10px 20px;
    border: 1px solid #dadada;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 11px;
    margin: 30px 27px;
    color: #778696;

    .provider-logo {
      position: absolute;
      display: inline-block;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 135px;
      padding: 5px;
      top: -26px;
      left: 14px;

      img {
        border: 1px solid #ddd;
        border-radius: 135px;
        width: 35px;
        height: 35px;
      }
    }

    .information-wrapper {
      position: relative;
      width: 100%;

      .description {
        width: 205px;
        margin-left: 19px;
        margin-top: 39px;
      }

      .title-wrapper {
        display: grid;
        grid-template-columns: 1fr 24px;
        align-items: center;
        width: 100%;

        .title {
          position: relative;
          top: 18px;
          left: 3px;
          min-width: 230px;
          flex-flow: wrap;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 12px 0px;
        }

        img {
          position: relative;
          top: 7px;
          transition: transform 150ms ease;
        }

        .invert-caret {
          transform: rotate(-180deg);
        }
      }
    }

    .rating {
      position: absolute;
      background-color: #ececec;
      padding: 8px 20px;
      margin-top: -11px;
      border-bottom-left-radius: 11px;
      right: -1px;
      font-weight: bold;
      width: 66px;
      border: 1px solid #dadada;
    }

    .instrument-code {
      position: absolute;
      background-color: #ececec;
      padding: 8px 8px;
      margin-top: -9px;
      border-top-left-radius: 11px;
      right: -1px;
      bottom: -1px;
      font-weight: bold;
      width: 66px;
      border: 1px solid #dadada;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }

    .extra-info-wrapper {
      position: relative;
      left: -21px;
      border-top: 1px solid #dadada;
      top: 44px;
      width: 288px;
      padding: 20px 15px 0 26px;
      height: 195px;
      font-size: 12px;

      >p {
        line-height: 10px;
      }
    }

    .is-extended {
      &.instrument-code {
        margin-top: 10px;
      }
    }

    .button-invest {
      position: absolute;
      bottom: -24px;
      width: 110px;
      text-align: center;
      height: 30px;
      font-size: 11px;
    }

    h4 {
      font-size: 1.2em;
      white-space: nowrap;
    }
  }

  @media only screen and (max-width: 1023px) {
    .product {
      width: 100%;
      margin: 30px 0px;
    }
  }
}