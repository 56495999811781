@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

a {
    color: $bs-info;
}
h4 {
    font-size: 22px;
    font-weight: 900;
}
h5 {
    font-size: 20px;
    font-weight: 900;  
}

pre {
    font: inherit;
    margin: 0;
}

.text-negative {
    color: $text-negative;
}

.text-positive {
    color: $text-positive;
}

// Input override
.form-control {
    border-radius: 3px;
    border: $form-border solid 1px;

    &[readonly] {
        color: #bfc7e0;
        background: #fff;
    }
}
.form-group.row .col-form-label {
    font-weight: normal;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.btn {
    min-width: 128px;
    text-transform: uppercase;
    border-radius: 8px;
}
.btn.btn-sm {
    min-width: 64px;
}

.neo-grid-button-column .btn-sm {
    min-width: auto;
}

.input-group-append .btn {
    min-width: auto;
}

.btn-link-inline {
    padding: 0;
    box-shadow: none;
}

.btn.form-btn {
    margin-top: 31px;
    width: 100%;
}

div.form-group {
    label {
        margin-bottom: 0.25rem;
        font-weight: 600;
    }
}

.larger-text {
    font-size: 18px
}

.custom-control-label::before {
    width: 1.25rem;
    height: 1.25rem;
    border: $form-border solid 1px;
    border-radius: 5px;
    top: 0px;
    left: -26px;
}
.custom-control-label {
    top: 2px;
    left: 2px;
}
.custom-control-label::after {
    width: 1.25rem;
    height: 1.25rem;
    top: 0px;
    left: -26px;
}

.invalid-feedback {
    font-size: 13px;
}

.content-box {
    border: 1px solid #eaedf7;
    box-shadow: 0 2px 4px 0 rgba(28, 41, 90, 0.04);
    background: #fff;
    padding: 1rem 1.5rem;
    border-radius: 10px;

    &.grey {
        background: #efefef;
        border-color: #e9e9e9;
        box-shadow: none;
    }
}

.fieldset-content-box {
    border: 1px solid #eaedf7;
    box-shadow: 0 2px 4px 0 rgba(28, 41, 90, 0.04);
    background: #fff;
    padding: .6rem 1.5rem;
    border-radius: 10px;

    > label {
        font-size: medium;
        font-weight: normal;
        padding: 0 0 0.4rem 0;
    }

    fieldset {
        align-content: flex-start;
        min-height: fit-content;
        
        label {
            font-size: small;
            font-weight: normal;
        }
    }
}

@media only screen and (max-width: 768px) {
    .row {
        display: block;
        margin-right: -7px;
        margin-left: -7px;
    }
    
    .flex-centered {
        display: flex;
        align-items: center;
        flex-flow: column;
    }

    .fieldset-content-box {
        max-width: 100%;
        margin-bottom: 15px;
        margin-left: 0px !important;
        margin-right: 0px !important; 
    }

  
    .content-box {
        margin-left:0px !important;
        margin-top: 15px;
    }

    .page-container {
        margin-top: 15px;
    }   
}

.page-container {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;

    .page-container-center {
        margin: 0 auto;
        max-width: 800px;
    }
}

@media only screen and (max-width: 576px) {
    .page-container {
        padding: 5px;
    }
} 

.status-image {
    img {
        height: 53px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .row {
        display: block;
    }

    .fieldset-content-box {
        max-width: 250px !important;
        margin-bottom: 10px;
        margin-left: 15px !important;
        margin-right: 0px !important;
    }

    .total-amounts {
        margin-top: 10px;
        max-width: 100%;
        margin-top: 25px;
    }

    .purchase-section {
        max-width: 100% !important;
    }
}

.info-icon {
    background-color: #FB2648;
    color: #fff;
    font-size: 10px;
    padding: 4px 4px 2px 5px;
    border-radius: 50%;
}

.upload-section {
    label {
        font-weight: 600;
    }
    .custom-control.custom-radio {
        padding-bottom: 0.5rem;
        color: $text-light;
    }
}

.row-breakdown {
    display: flex;
}


// Alerts and toasts
@each $color, $value in $theme-colors {
    .toast-header-#{$color} {
        @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
    }
    .toast-count-down-#{$color} {
        background-color: theme-color-level($color, -3);
    }
  }

.alert-warning {
    @include alert-variant(theme-color-level("warning", -9), theme-color-level("warning", -6), theme-color-level("warning", 7));
}
.alert-dark {
    @include alert-variant(theme-color-level("dark", -3), theme-color-level("dark", -1), $bs-light);
}
.toast-header-warning {
    @include alert-variant(theme-color-level("warning", -7), theme-color-level("warning", -4), theme-color-level("warning", $alert-color-level));
}
.toast-header-dark {
    @include alert-variant(theme-color-level("dark", -8), theme-color-level("dark", -4), theme-color-level("dark", $alert-color-level));
}

/* Card overrides */
.neo-card.card {
    margin-top: 0;
    margin-bottom: 15px;
    border-radius: 0px;
    border-color: lighten($form-border, 3%);

    .card-header {
        background: lighten($form-widget-color, 2%);
        padding: 10px 20px;
        padding-top: 0;
        border-bottom-color: lighten($form-border, 3%);
        margin-bottom: 0;

        i {
            margin-right: 10px;
        }
    }
    .card-body {
        padding: 20px;
    }
}

@media (max-width: 1360.98px) {
    .neo-card.card {
        border: none;
    
        .card-header {
            background: none;
            padding: 10px 0px;
            font-weight: 600;
        }
        .card-body {
            padding: 15px 0;
        }
    }
}

.product-container {
    @media (min-width: 768px) and (max-width: 1023px) {
        margin-left: 15px;
    } 
}

.neo-tooltip {
    background-color: $text-color;
    border-radius: 10px;

    &.horizontal {
        border-radius: 5px;
    }
}

.react-select-bs {
    flex-grow: 1;

    .react-select-bs__control {
        border: 1px solid $form-border;
        border-radius: 1px;
        min-height: calc(1.5em + .75rem + 2px);

        &.react-select-bs__control--menu-is-open, &.react-select-bs__control--is-focused {
            color: #495057;
            border-color: $input-focus-border-color;
            outline: 0;
            box-shadow: $input-focus-box-shadow;
        }
    }
}