.investor-selection-component {
    display: flex;
    
    .investment-type-heading {
        margin-left: 1rem;

        h4 {
            font-size: 24px;
        }
    }

    h4 {
        display: inline;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
        margin: 0;   
    }

    .sub-text {
        color: $text-light;
    }
}

.dashboard-status-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $text-lighter;
    min-height: 170px;
    line-height: 1.33;

    a {
        display: block;
        margin: 0.5rem 0;
    }
    small {
        font-size: 12px;
    }
    .btn {
        min-width: 140px;
    }
}

@include media-breakpoint-down(sm) {
    .investor-selection-component {
        margin-bottom: 1rem;
    }
}
@include media-breakpoint-down(xs) {
    .investor-selection-component {

        h4 {
            font-size: 18px;
        }

        .investment-type-heading {
            margin-top: 2px;
            margin-left: 2rem;
    
            h4 {
                font-size: 18px;
            }
        }
    }
}