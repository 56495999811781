.dashboard-invest-now {
    padding-top: 100px;
    text-align: center;

    .btn {
        width: 150px;
    }
}

.dashboard-header {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: -1rem;

    >div {
        margin-top: 1rem;
    }

    .dashboard-top-right {
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
        display: flex;
        margin-right: 1px;

        .irr-value {
            font-weight: 600;
            font-size: 20px;
        }
    }

    @media (max-width: 768px) {
        .dashboard-top-right {
            width: 100%;
            place-content: center;
        }
    }
}

@media (max-width: 1023px) {
    .dashboard-header {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
}


.dashboard-value-summary {

    .content-box {
        display: grid;
        grid-template-rows: 30% auto 25%;
        min-height: 16vh;
        height: 100%;
        grid-template-areas:
            "values header"
            "values header"
            "links links";

        .header {
            grid-area: header;
            align-content: center;
            text-align: end;


            img {
                height: 35px;
                object-fit: contain;
            }
        }

        .value-wrapper {
            grid-area: values;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;

            .value {
                display: grid;
                grid-template-rows: 25% auto;
                align-items: center;
                align-content: center;
                row-gap: 2px;
                
                >small {
                    color: $text-lighter;
                    font-size: initial;
                    text-wrap: balance;
                }
                
                .amount {
                    font-size: 26px;
                    font-weight: 900;
                    color: #242F57;
                }
            }
        }

        .links-wrapper {
            grid-area: links;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .link {
                display: flex;
                align-items: center;
                width: 100%;
                text-wrap: balance;
            }

            :only-child, :last-child {
                justify-content: flex-end;
            }
        }
    }
}

.dashboard-main-graph-header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .graph-title {
        h4 {
            margin-bottom: 0;
        }

    }

    .graph-criteria {
        display: flex;
        padding: 0.5rem 1rem;
    }

    small {
        color: $text-lighter;
        font-size: 13px;
    }
}

@include media-breakpoint-up(md) {
    .dashboard-invest-now {
        padding-top: calc(30vh - 100px);
    }
}

.available-box {
    display: inline-block;
    background: #dbf7f0;
    color: #1fd0a3;
    border-radius: 8px;
    padding: 0.375rem 1rem;
    min-width: 95px;

    &.pending {
        background: #e7e6fe;
        color: #6863fb;
    }
}