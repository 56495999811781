.trade-confirmation {

    .subtitle {
      padding: 15px 0;
    }

    label.custom-control-append-label {
      margin-top: 0.15rem;
      font-weight: 600;
      margin-left: 0.4rem;

      a {
        color: $etfSA-black-primary;
      }
    }

    .modal-body {
      .message {
        margin: 15px 0;
        font-weight: 400;
      }
    }
  }