.popover-drop-anchor {
    display: inline-flex;
    border: 2px solid #d2d5e1;
    background: #fff;
    border-radius: 5px;
    color: #7c88b1;
    padding: 2px 4px;
    position: relative;
    margin-left: 1rem;
    cursor: pointer;
}

.popover-container {
    position: absolute;
    z-index: 10;
    transition: opacity ease 0.5s;
    border: 1px solid #eaedf7;
    box-shadow: 0 4px 8px 0 rgba(28, 41, 90, 0.04);
    border-radius: 10px;
    background: #fff;

    .popover-arrow {
        position: absolute;
        top: -14px;
        right: 19px;
        z-index: 1;

        &.arrow-center {
            right: calc(50% - 15px);
        }
    }

    .popover-body {
        padding: 0.5rem 0;
        text-align: left;
        color: $text-color;
        min-width: 200px;

        .popover-item {
            padding: 0.5rem 1.5rem;
            border: 1px solid transparent;
            border-left: none;
            border-right: none;
            cursor: pointer;

            small {
                font-size: 12px;
                display: block;
                color: $text-light;
            }

            &:hover {
                border-color: #eaedf7;
                background-color: #f4faff;
            }
        }

        .popover-item-divider {
            border-bottom: 1px solid #eaedf7;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

.dropdown-menu {
    border: 1px solid #eaedf7;
    box-shadow: 0 4px 8px 0 rgba(28, 41, 90, 0.04);
    border-radius: 10px;

    .dropdown-item {
        font-size: 15px;
        border: 1px solid transparent;
        border-left: none;
        border-right: none;
        color: $bs-primary;

        &:hover {
            border-color: #eaedf7;
            background: #f4faff;
        }
    }
}

.popover-container.instruments {
    .popover-body {
        font-size: 14px;
        min-width: 300px;
    }
}